export const isEmpty = (value: any) => {
    if (value === undefined || value === null) {
        return true;
    }
    const type = typeof value;
    switch (type) {
        case 'string': {
            return value === '';
        }
        case 'number': {
            return false;
        }
        case 'object': {
            return Object.entries(value).length === 0;
        }
        case 'boolean': {
            return false;
        }
        default: {
            return false;
        }
    }
};
