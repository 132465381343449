// tslint:disable: variable-name
import { isEmpty } from '../utils/validations';

export class PurchaseCheckout {
    public id: number;
    public user_id: number;
    public quantity: number;
    public fullname: string;
    public email: string;
    public phone: string;
    public shipment_id: number;

    constructor(object?: any) {
        object = object ? object : {};
        this.id = isEmpty(object.id) ? null : object.id;
        this.user_id = isEmpty(object.user_id) ? null : object.user_id;
        this.quantity = isEmpty(object.quantity) ? null : object.quantity;
        this.fullname = isEmpty(object.fullname) ? null : object.fullname;
        this.email = isEmpty(object.email) ? null : object.email;
        this.phone = isEmpty(object.phone) ? null : object.phone;
        this.shipment_id = isEmpty(object.shipment_id) ? null : object.shipment_id;
    }
}
