import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { ShipmentService } from '../services/shipment.service';
import { isEmpty } from '../utils/validations';

@Injectable({
    providedIn: 'root'
})
export class ThankYouGuard implements CanActivate {
    public isCheckoutInit = false;

    constructor(
        private shipment: ShipmentService,
        private router: Router,
        private loader: LoaderService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.checkShipment(next.queryParams, state.url);
    }

    private async checkShipment(queryParams: Params, url: string): Promise <boolean> {
        const isShippingRoute = url.indexOf('shipment-info') >= 0;
        const isThankYouRoute = url.indexOf('thank-you') >= 0;
        try {
            this.loader.show();
            await this.shipment.getCheckout(queryParams.checkout);
            if (!this.isCheckoutInit) {
                this.isCheckoutInit = true;
            }
            if (isShippingRoute) {
                if (this.shipment.complete) { this.router.navigate(['thank-you'], { queryParams }); }
                if (isEmpty(queryParams.checkout)) { this.router.navigateByUrl('/error'); }
                return true;
            } else if (isThankYouRoute) {
                if (this.shipment.complete) { return true; }
                return this.router.navigate(['shipment-info'], { queryParams });
            }
        } catch (error) {
            this.router.navigate(['error', 'get-checkout'], { queryParams });
            return true;
        } finally {
            this.loader.hide();
        }
    }

}
