// tslint:disable: variable-name
import { isEmpty } from '../utils/validations';

export class Address {
    public id: string;
    public calle: string;
    public codigo_postal: string;
    public colonia: string;
    public contacto: string;
    public descripcion: string;
    public estado_id: number;
    public estado_nombre: string;
    public exterior: string;
    public interior: string;
    public municipio_id: number;
    public municipio_nombre: string;
    public referencias: string;
    public telefono: string;

    constructor(object?: any) {
        object = object ? object : {};
        this.id = isEmpty(object.id) ? null : object.id;
        this.calle = isEmpty(object.calle) ? null : object.calle;
        this.codigo_postal = isEmpty(object.codigo_postal) ? null : object.codigo_postal;
        this.colonia = isEmpty(object.colonia) ? null : object.colonia;
        this.contacto = isEmpty(object.contacto) ? null : object.contacto;
        this.descripcion = isEmpty(object.descripcion) ? null : object.descripcion;
        this.estado_id = isEmpty(object.estado_id) ? null : object.estado_id;
        this.estado_nombre = isEmpty(object.estado_nombre) ? null : object.estado_nombre;
        this.exterior = isEmpty(object.exterior) ? null : object.exterior;
        this.interior = isEmpty(object.interior) ? null : object.interior;
        this.municipio_id = isEmpty(object.municipio_id) ? null : object.municipio_id;
        this.municipio_nombre = isEmpty(object.municipio_nombre) ? null : object.municipio_nombre;
        this.referencias = isEmpty(object.referencias) ? null : object.referencias;
        this.telefono = isEmpty(object.telefono) ? null : object.telefono;
    }

    public static initAddresses(addresses: any[]): Address[] {
        return addresses.map(address => new Address(address));
    }
}
