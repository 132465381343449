// tslint:disable: variable-name
import { isEmpty } from '../utils/validations';
import { PurchaseCheckout } from './purchase-checkout';

export class Checkout {
    public id: string;
    public total: number;
    public externalId: string;
    public purchase: PurchaseCheckout;

    constructor(object?: any) {
        object = object ? object : {};
        this.id = isEmpty(object.id) ? null : object.id;
        this.total = isEmpty(object.total) ? null : object.total;
        this.externalId = isEmpty(object.externalId) ? null : object.externalId;
        this.purchase = new PurchaseCheckout(object.purchase);
    }
}
