
export class ErrorsUtils {
    public errors: string[];
    public status: boolean;
    public error: any;

    constructor(error = null, status = null, errors = []) {
        this.error = error ? error : null;
        this.status = status ? status : null;
        this.errors = errors ? errors : [];
    }

    public static joinErrors(error: any): any[] {
        return Object.keys(error).map(key => error[key][0]);
    }

    public getErrorsAsText(separator: string): string {
        return this.errors.join(separator);
    }
}
