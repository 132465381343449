export const environment = {
    production: false,
    CONSTANTS: {
        EXOTEC_URL: 'https://test.exocet.billpocket.com/api/v1',
        ARIANE_URL: 'https://test.ariane.billpocket.services/api/v1',
        PAYWITH_URL: 'https://test.paywith.billpocket.com/api/v1',
        BILLPOCKET_WEBAPP_URL: 'https://test.bpckt.com/panel',
        PAGO_DISTANCIA_PAGE_URL: 'https://pagoadistancia.billpocket.com',
        NIMITZ_URL: 'https://test.nimitz.billpocket.services/api/v2',
        RECAPTCHA_KEY: '6LejaLsUAAAAAAl6Q3P8gZ24N3q8tYzKSoFQ2s2b',
        RECAPTCHA_TYPE: 'ecommerce'
    },
    REGEX: {
        EMAIL_REGEX: /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,5})+$/,
        AMEX: /^3[47][0-9]{13}$/,
        MASTERCARD: /(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}/,
        VISA: /^4[0-9]{12}(?:[0-9]{3})?/,
        DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        JCB: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
        DINNERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]/
    }
};
