<header class="py-4" fxLayout="column" fxLayoutAlign="center center">
    <img height="120px" src="assets/images/pago_distancia.png" alt="Pago a Distancia de Billpocket">
</header>
<router-outlet></router-outlet>
<div class="dark-loader-overlay" *ngIf="loader.isShowing">
    <mat-progress-spinner mode="indeterminate">
    </mat-progress-spinner>
</div>
<footer class="py-4" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px">
    <p class="pay-process-title">Pago procesado de manera segura con:</p>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="px-3 my-1">
            <img height="50px" src="assets/images/mc_sc.svg" alt="Mastercard">
        </div>
        <div class="px-3 my-1">
            <img height="30px" src="assets/images/billpocket_horizontal_azul.svg" alt="Billpocket">
        </div>
        <div class="px-3 my-1" fxFlexOrder.lt>
            <img height="30px" src="assets/images/verified_visa.svg" alt="Visa">
        </div>
    </div>
</footer>
