import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThankYouGuard } from 'src/app/shared/guard/thank-you.guard';

const routes: Routes = [
    {
        path: 'shipment-info',
        loadChildren: () => import('./shipment-form/shipment-form.module').then(m => m.ShipmentFormModule),
        canActivate: [ThankYouGuard]
    },
    {
        path: 'thank-you',
        loadChildren: () => import('./thank-you/thank-you.module').then(m => m.ThankYouModule),
        canActivate: [ThankYouGuard]
    },
    {
        path: 'error',
        loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule),
    },
    { path: '**', pathMatch: 'full', redirectTo: 'error' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
