import { Injectable } from '@angular/core';
import { Address } from 'src/app/shared/model/address';
import { ExotecService } from 'src/app/shared/services/exotec.service';
import { environment } from 'src/environments/environment';
import { Checkout } from 'src/app/shared/model/checkout';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { isEmpty } from '../utils/validations';

@Injectable({
    providedIn: 'root'
})
export class ShipmentService {
    public address: Address;
    public addressId: string;
    public userAddresses: Address[];
    public manualAddress: boolean;
    public complete: boolean;
    public checkout: Checkout;

    constructor(private exotec: ExotecService, private http: HttpClient) {
        this.address = new Address();
        this.complete = false;
        this.checkout = new Checkout();
    }

    public async getNeighborhoods(): Promise<string[]> {
        const zipCodeInformation = await this.exotec.getZipCodeInformation(this.address.codigo_postal);
        this.address.municipio_nombre = zipCodeInformation.municipio.name;
        this.address.estado_nombre = zipCodeInformation.estado.name;
        return zipCodeInformation.colonias;
    }

    public async getCheckout(checkoutId: string): Promise<void> {
        try {
            this.checkout = await this.http.get(`${environment.CONSTANTS.PAYWITH_URL}/checkout/pad/${checkoutId}`)
                .pipe(map((value: any) => new Checkout(value))).toPromise();
            this.complete = !isEmpty(this.checkout.purchase.shipment_id);
            this.address.contacto = this.checkout.purchase.fullname;
            this.address.telefono = this.checkout.purchase.phone;
        } catch (error) {
            throw new Error('Tuvimos problemas al cargar información de tu compra, por favor reintente.');
        }
    }

    public async getAddresses(): Promise<void> {
        try {
            this.userAddresses = await this.http.get(`${environment.CONSTANTS.ARIANE_URL}/addresses/${this.checkout.purchase.user_id}`)
                .pipe(map((value: any) => Address.initAddresses(value.data))).toPromise();
            this.manualAddress = this.userAddresses.length === 0;
            const defaultAddr = this.userAddresses[0];
            this.addressId = defaultAddr ? defaultAddr.id : null;
        } catch (error) {
            throw new Error('Tuvimos problemas al cargar tus domicilios, por favor reintente.');
        }
    }


    public async sendShipmentInfo(): Promise <any> {
        const params: any = {
            address_id: !this.manualAddress ? this.addressId : null,
            address: this.manualAddress ? {
                user_id: this.checkout.purchase.user_id,
                receiver: this.address.contacto,
                phone: this.address.telefono,
                street: this.address.calle,
                ext: this.address.exterior,
                int: this.address.interior,
                neighborhood: this.address.colonia,
                municipality: this.address.municipio_nombre,
                state: this.address.estado_nombre,
                zip_code: this.address.codigo_postal,
                reference: this.address.referencias,
            } : {
                phone: this.address.telefono
            },
        };
        return this.http.post(`${environment.CONSTANTS.NIMITZ_URL}/purchases/${this.checkout.purchase.id}/add-address`, params).toPromise();
    }

    public redirectToPanel(): void {
        window.location.href =
            `${environment.CONSTANTS.BILLPOCKET_WEBAPP_URL}/login?action=login&userEmail=${this.checkout.purchase.email}`;
    }
}
