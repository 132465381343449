import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomBreakpointsProvider } from 'src/app/shared/material/custom-breakpoints';
import '@angular/common/locales/global/es-MX';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [ AppComponent ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatProgressSpinnerModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-MX' },
        CustomBreakpointsProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
