import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ZipCodeInformation } from '../interfaces/zipCodeInformation';
import { ErrorsUtils } from '../utils/errors';

@Injectable({
    providedIn: 'root'
})
export class ExotecService {

    constructor(protected http: HttpClient) { }

    public async getZipCodeInformation(zipCode: string): Promise<ZipCodeInformation> {
        try {
            const response = this.http.get(`${environment.CONSTANTS.EXOTEC_URL}/zip?zipCode=${zipCode}`).toPromise();
            return await response as ZipCodeInformation;
        } catch (error) {
            throw new ErrorsUtils(error, error.status, [error.error.message]);
        }
    }

    public async completePurchase(information): Promise<any> {
        try {
            return await this.http.post(`${environment.CONSTANTS.EXOTEC_URL}/campaign/marketing`, information).toPromise();
        } catch (error) {
            let errors = [];
            if (error.status === 400) {
                errors = [error.error.message];
            } else if (error.status === 422) {
                errors = ErrorsUtils.joinErrors(error.error);
            } else {
                errors = ['Ha surgido un error al realizar tu compra, por favor vuelve a intentarlo.'];
            }
            throw new ErrorsUtils(error, error.status, errors);
        }
    }

    public async changePassword(password: string, usuaSubId: string): Promise<any> {
        try {
            return await this.http.patch(`${environment.CONSTANTS.EXOTEC_URL}/users/change-password`, {
                password,
                usua_sub_id: usuaSubId,
            }).toPromise();
        } catch (error) {
            let errors = [];
            if (error.status === 422) {
                errors = ErrorsUtils.joinErrors(error.error);
            } else {
                errors = ['Ha surgido un error al realizar tu compra, por favor vuelve a intentarlo.'];
            }
            throw new ErrorsUtils(error, error.status, errors);
        }
    }
}
